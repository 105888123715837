import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';
import ESimHomePage from '../../esims/pages/ESimHomePage';
import { ssrESimCommonMessages } from '../../localization/ssr/esim/common';
import { ssrESimFAQMessages } from '../../localization/ssr/esim/faq';
import { ssrESimFooterMessages } from '../../localization/ssr/esim/footer';
import { ssrESimHeaderMessages } from '../../localization/ssr/esim/header';
import { ssrESimHomeMessages } from '../../localization/ssr/esim/home';
import { getUserInfoSSR } from '../../utils/cf';
import { getSelectedLocaleForSSR } from '../../utils/getSelectedLocaleForSSR';

const ESims: React.FC = () => {
  const router = useRouter();
  return (
    <>
      <Head>
        <title>Driffle eSIM - Affordable Global Connectivity</title>
        <meta httpEquiv="last-modified" content={new Date().toDateString()} />
        <meta
          name="description"
          content="Explore the world with Driffle eSIM. Cheap rates, easy activation, and reliable global connectivity for all your travel needs."
        />
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href={`https://driffle.com/esim`} />
        {/* <link
          rel="alternate"
          href="https://driffle.com/esim"
          hrefLang="x-default"
        /> */}
        {/* OG Meta */}
        <meta
          property="og:title"
          content="Driffle eSIM - Affordable Global Connectivity"
        />
        <meta property="og:type" content="Website" />
        <meta
          property="og:description"
          content="Explore the world with Driffle eSIM. Cheap rates, easy activation, and reliable global connectivity for all your travel needs."
        />
        <meta property="og:url" content="https://driffle.com/esim" />
        <meta property="og:site_name" content="Driffle" />
        <meta
          property="og:image"
          content="https://static.driffle.com/site-assets/logo-white.png"
        />
        <meta property="fb:app_id" content="1787660858154522" />
        {/* Twitter Meta */}
        <meta
          property="twitter:title"
          content="Driffle eSIM - Affordable Global Connectivity"
        />
        <meta
          property="twitter:description"
          content="Explore the world with Driffle eSIM. Cheap rates, easy activation, and reliable global connectivity for all your travel needs."
        />
        <meta property="twitter:url" content="https://driffle.com" />
        <meta
          property="twitter:image"
          content="https://static.driffle.com/site-assets/logo-white.png"
        />
        <meta name="twitter:card" content="summary" />
      </Head>
      <ESimHomePage />
    </>
  );
};

export default ESims;

export async function getServerSideProps(context: any) {
  try {
    const userInfo = await getUserInfoSSR(context.req, context.res);
    const localeData = await import(
      `../../localization/languages/${context?.locale ?? 'en'}.ts`
    );

    const ssrMessages = getSelectedLocaleForSSR(localeData.default, {
      ...ssrESimHomeMessages,
      ...ssrESimCommonMessages,
      ...ssrESimFAQMessages,
      ...ssrESimFooterMessages,
      ...ssrESimHeaderMessages,
    });
    return {
      props: {
        reduxInitialState: userInfo,
        locale: context?.locale ?? 'en',
        localeData: ssrMessages,
      },
    };
  } catch (err) {
    return {
      redirect: {
        permanent: false,
        destination: '/esim/404',
      },
    };
  }
}
