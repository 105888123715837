import React from 'react';
import CheckDeviceCompatibility from '../../../components/CheckDeviceCompatibility';
import FAQSection from '../../../components/FAQSection/FAQSection';
import { PageContentContainer } from '../../../ESimStyles';
import ESimLayout from '../../../layouts/ESimLayout';
import CatalogSection from './components/CatalogSection';
import HomeHero from './components/HomeHero';
import RegionSection from './components/RegionSection';
import StepsSection from './components/StepsSection';
import { HomePageContainer } from './HomePageDesktopStyles';

const ESimHomePageDesktop: React.FC = () => {
  return (
    <ESimLayout>
      <PageContentContainer>
        <HomePageContainer>
          <HomeHero />
          <CatalogSection />
          <RegionSection />
          {/* <CompatibilitySection /> */}
          {/* <ExtraInfoSection /> */}
          <StepsSection />
          <CheckDeviceCompatibility />
          <FAQSection />
        </HomePageContainer>
      </PageContentContainer>
    </ESimLayout>
  );
};

export default ESimHomePageDesktop;
