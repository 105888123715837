import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { MaskIcon } from '../../../../../components/Icons/Icons';
import { useLocale } from '../../../../../contexts/LocalizationContext';
import { saveEventV3 } from '../../../../../utils/eventTracking';
import {
  Redirect,
  SectionContainer,
  StepContainer,
  StepImageContainer,
  StepSectionContainer,
  StepsContainer,
} from '../HomePageDesktopStyles';

const StepsSection: React.FC = () => {
  const router = useRouter();
  const [displayImage, setDisplayImage] = useState<number>(0);
  const { messages } = useLocale();
  const {
    esim_home_the_easiest_way_to_stay_msg,
    esim_home_buy_a_data_plan_msg,
    esim_home_search_for_your_destination_and_msg,
    esim_home_install_the_esim_msg,
    esim_home_easy_installation_via_qr_code_msg,
    esim_home_start_your_plan_msg,
    esim_home_activate_your_data_plan_anytime_msg,
    esim_home_wait_i_have_more_questions_msg,
  } = messages || {};

  const handleHover = (index: number) => {
    setDisplayImage(index);
  };

  return (
    <SectionContainer>
      <StepSectionContainer>
        <h2>{esim_home_the_easiest_way_to_stay_msg}</h2>
        <div>
          <div style={{ flexGrow: 1 }}>
            <StepsContainer>
              <StepContainer onMouseEnter={() => handleHover(0)}>
                <div>
                  <Image src="/icons/esims/card-icon.svg" layout="fill" />
                </div>
                <div>
                  <p>{esim_home_buy_a_data_plan_msg}</p>
                  <p>{esim_home_search_for_your_destination_and_msg}</p>
                </div>
              </StepContainer>
              <StepContainer onMouseEnter={() => handleHover(1)}>
                <div>
                  <Image
                    src="/icons/esims/install-esim-icon.svg"
                    layout="fill"
                  />
                </div>
                <div>
                  <p>{esim_home_install_the_esim_msg}</p>
                  <p>{esim_home_easy_installation_via_qr_code_msg}</p>
                </div>
              </StepContainer>
              <StepContainer onMouseEnter={() => handleHover(2)}>
                <div>
                  <Image src="/icons/esims/airplane-icon.svg" layout="fill" />
                </div>
                <div>
                  <p>{esim_home_start_your_plan_msg}</p>
                  <p>{esim_home_activate_your_data_plan_anytime_msg}</p>
                </div>
              </StepContainer>
            </StepsContainer>
            <Link href="/esim/faqs" passHref>
              <a
                onClick={() => {
                  saveEventV3({
                    action: 'click',
                    category: 'more_questions',
                    label: '',
                    value: [''],
                    properties: '',
                    from: router,
                  });
                }}
              >
                <Redirect>
                  <p>{esim_home_wait_i_have_more_questions_msg}</p>
                  <MaskIcon
                    url="/icons/arrow_right_alt.svg"
                    width="24px"
                    height="24px"
                    margin="0"
                    selected
                    color="#fff"
                  />
                </Redirect>
              </a>
            </Link>
            {/* <Redirect>
              <p>See if your device can use our eSIM</p>
              <MaskIcon
                url="/icons/arrow_right_alt.svg"
                width="24px"
                height="24px"
                margin="0"
                selected
                color="#fff"
              />
            </Redirect> */}
          </div>
          <div style={{ position: 'relative', minHeight: '439px' }}>
            <StepImageContainer
              style={displayImage === 0 ? { opacity: 1 } : {}}
            >
              <Image src="/images/esims/esim-step-1.webp" layout="fill" />
            </StepImageContainer>
            <StepImageContainer
              style={displayImage === 1 ? { opacity: 1 } : {}}
            >
              <Image src="/images/esims/esim-step-2.webp" layout="fill" />
            </StepImageContainer>
            <StepImageContainer
              style={displayImage === 2 ? { opacity: 1 } : {}}
            >
              <Image src="/images/esims/esim-step-3.webp" layout="fill" />
            </StepImageContainer>
          </div>
        </div>
      </StepSectionContainer>
    </SectionContainer>
  );
};

export default StepsSection;
