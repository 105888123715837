import { CircularProgress } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef } from 'react';
import { CircleFlag } from 'react-circle-flags';
import { useTheme } from 'styled-components';
import { MaskIcon } from '../../../../../../components/Icons/Icons';
import { useLocale } from '../../../../../../contexts/LocalizationContext';
import { saveEventV3 } from '../../../../../../utils/eventTracking';
import { RegionsMap } from '../../../../../constants';
import { renderCountryName } from '../../../../../helper';
import useSearch from '../../../../../hooks/useSearch';
import {
  FlagContainer,
  NoResultsFound,
  SearchContainer,
  SearchIconContainer,
  SearchInput,
  SearchResultItem,
  SearchResultsContainer,
} from './SearchStyles';

const Search: React.FC = () => {
  const theme = useTheme();
  const router = useRouter();
  const { messages } = useLocale();
  const { esim_home_where_are_you_visiting_msg } = messages || {};
  const searchContainerRef = useRef<null | HTMLDivElement>(null);
  const {
    handleChange,
    results,
    loading,
    searchQuery,
    openResultsDropdown,
    setOpenResultsDropdown,
  } = useSearch();

  const inputRef = useRef<null | HTMLInputElement>(null);

  const handleFocus = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    handleFocus();
  }, [handleFocus]);

  const handleClickOutside = useCallback(
    (e: any) => {
      const { target } = e;
      if (searchContainerRef.current) {
        if (!searchContainerRef.current.contains(target)) {
          setOpenResultsDropdown(false);
        }
      }
    },
    [searchContainerRef]
  );
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <SearchContainer ref={searchContainerRef} onClick={handleFocus}>
        <SearchIconContainer>
          {loading ? (
            <CircularProgress
              sx={{
                color: theme.palette.text.t2,
              }}
              size={20}
            />
          ) : (
            <MaskIcon
              url="/icons/search-24.svg"
              width="24px"
              height="24px"
              margin="0"
              selected
              color={theme.palette.text.t2}
            />
          )}
        </SearchIconContainer>
        <SearchInput
          ref={inputRef}
          onChange={handleChange}
          placeholder={esim_home_where_are_you_visiting_msg}
          type="text"
        />
        {openResultsDropdown && (
          <SearchResultsContainer>
            {results.length > 0 ? (
              results.map((cur, i) => (
                <Link passHref href={`/esim/${cur.displaySlug}`}>
                  <a
                    onClick={() => {
                      saveEventV3({
                        action: 'click',
                        category: 'search',
                        label: '',
                        value: [cur.displaySlug],
                        properties: '',
                        from: router,
                      });
                    }}
                  >
                    <SearchResultItem key={i}>
                      <FlagContainer>
                        <div
                          style={
                            cur.type === 'regional'
                              ? {
                                  background:
                                    RegionsMap[cur.countryName.toLowerCase()]
                                      ?.bg ?? '',
                                }
                              : {}
                          }
                        >
                          {cur.type === 'country' && (
                            <CircleFlag
                              countryCode={cur.countryCode.toLowerCase()}
                            />
                          )}
                        </div>
                      </FlagContainer>
                      <p>{renderCountryName(cur.countryName)}</p>
                    </SearchResultItem>
                  </a>
                </Link>
              ))
            ) : (
              <NoResultsFound>
                <p>No Results Found</p>
              </NoResultsFound>
            )}
          </SearchResultsContainer>
        )}
      </SearchContainer>
    </>
  );
};

export default Search;
