import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { MaskIcon } from '../../../components/Icons/Icons';
import Button from '../../../components/NewButton/Button';
import { useLocale } from '../../../contexts/LocalizationContext';
import { saveEventV3 } from '../../../utils/eventTracking';
import { SectionContainer } from '../../pages/ESimHomePage/HomePageDesktop/HomePageDesktopStyles';
import {
  CompatibilitySectionContainer,
  ContentContainer,
  HeadingContainer,
  ImageBackground,
  ImageContainer,
  Subtitle,
} from './CheckoutDeviceCompatibilityStyles';

const DesktopCheckoutDeviceCompatibility: React.FC = () => {
  const router = useRouter();
  const { messages } = useLocale();
  const {
    esim_home_does_my_phone_support_esim_msg,
    esim_home_search_the_model_of_your_msg,
    esim_button_check_compatibility_msg,
  } = messages || {};

  return (
    <SectionContainer>
      <CompatibilitySectionContainer>
        <ContentContainer>
          <div>
            <HeadingContainer>
              <h2>{esim_home_does_my_phone_support_esim_msg}</h2>
            </HeadingContainer>
            <Subtitle>{esim_home_search_the_model_of_your_msg}</Subtitle>
            <Link passHref href="/esim/compatibility">
              <a
                onClick={() => {
                  saveEventV3({
                    action: 'click',
                    category: 'check_compatibility',
                    label: '',
                    value: [''],
                    properties: '',
                    from: router,
                  });
                }}
                style={{ width: 'fit-content', display: 'block' }}
              >
                <Button
                  style={{ color: '#000', background: '#fff' }}
                  buttonTheme="grey"
                >
                  {esim_button_check_compatibility_msg}{' '}
                  <MaskIcon
                    url="/icons/esims/arrow_right_alt.svg"
                    width="24px"
                    height="24px"
                    margin="0"
                    selected
                  />
                </Button>
              </a>
            </Link>
          </div>
        </ContentContainer>
        <div>
          <ImageBackground>
            <ImageContainer>
              <Image
                src="https://static.driffle.com/media-gallery/production/3fb18815-5f45-41bf-88ee-f06a9966f71f_phone-cut-bottom.webp"
                layout="fill"
              />
            </ImageContainer>
          </ImageBackground>
        </div>
      </CompatibilitySectionContainer>
    </SectionContainer>
  );
};

export default DesktopCheckoutDeviceCompatibility;
