import { Skeleton } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { CircleFlag } from 'react-circle-flags';
import { useTheme } from 'styled-components';
import { useESim } from '../../../../../contexts/ESimContext';
import { saveEventV3 } from '../../../../../utils/eventTracking';
import { renderCountryName, renderSlug } from '../../../../helper';
import {
  HomeMobileSection,
  MobileSlider,
  RegionCircleContainer,
  RegionContainer,
} from '../HomePageMobileStyles';

const RegionsSection: React.FC<{ type?: 'home' | 'search' }> = ({ type }) => {
  const { loading, regionSkuList, countrySkuList } = useESim();
  const router = useRouter();
  const theme = useTheme();

  return (
    <HomeMobileSection style={{ marginBottom: '32px' }}>
      {loading ? (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
          {Array.from(Array(2).keys()).map((cur) => (
            <div key={cur}>
              <Skeleton
                sx={{
                  background: theme.palette.background.bg2,
                  width: '230px',
                  marginLeft: '16px',
                  marginBottom: '16px',
                }}
                height={'24px'}
              />
              <MobileSlider>
                {Array.from(Array(10).keys()).map((cur) => (
                  <Skeleton
                    sx={{
                      background: theme.palette.background.bg2,
                      minWidth: '80px',
                    }}
                    key={cur}
                    variant="circular"
                    height="80px"
                    width="80px"
                  />
                ))}
              </MobileSlider>
            </div>
          ))}
        </div>
      ) : (
        regionSkuList
          .filter((cur) =>
            type === 'search'
              ? true
              : cur.countryName !== 'Global' && cur.countryName !== 'Oceania'
          )
          .slice(type === 'search' ? 0 : 4, regionSkuList.length - 1)
          .map((cur) => (
            <>
              <h2
                style={
                  type === 'search'
                    ? { fontSize: '16px', lineHeight: '20px' }
                    : {}
                }
              >
                {cur.countryName}
              </h2>
              <MobileSlider style={{ marginBottom: '32px' }}>
                {cur.roamingCountriesName.split(',').map((roamingCountry) => (
                  <Link
                    passHref
                    key={roamingCountry}
                    href={'/esim/' + renderSlug(roamingCountry)}
                  >
                    <a
                      onClick={() => {
                        saveEventV3({
                          action: 'click',
                          category: 'regional_country',
                          label: cur.countryName,
                          value: [renderSlug(roamingCountry)],
                          properties: 'mobile',
                          from: router,
                        });
                      }}
                    >
                      <RegionContainer>
                        <RegionCircleContainer
                          style={
                            type === 'search'
                              ? {
                                  minWidth: '66px',
                                  minHeight: '66px',
                                  height: '66px',
                                  width: '66px',
                                }
                              : {}
                          }
                        >
                          <div
                            style={
                              type === 'search'
                                ? { width: '40px', height: '40px' }
                                : {}
                            }
                          >
                            <CircleFlag
                              countryCode={countrySkuList
                                .filter(
                                  (country) =>
                                    country.countryName === roamingCountry
                                )[0]
                                ?.countryCode.toLowerCase()}
                            />
                          </div>
                        </RegionCircleContainer>
                        <p
                          style={
                            type === 'search'
                              ? {
                                  width: '66px',
                                  fontSize: '12px',
                                  lineHeight: '16px',
                                }
                              : {}
                          }
                        >
                          {renderCountryName(roamingCountry)}
                        </p>
                      </RegionContainer>
                    </a>
                  </Link>
                ))}
              </MobileSlider>
            </>
          ))
      )}
    </HomeMobileSection>
  );
};

export default RegionsSection;
