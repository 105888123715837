import React from 'react';
import {
  GWOnlyMobileWrapper,
  GWTabletWrapper,
} from '../../../styles/window.style';
import DesktopCheckoutDeviceCompatibility from './DesktopCheckoutDeviceCompatibility';
import MobileCheckoutDeviceCompatibility from './MobileCheckoutDeviceCompatibility';

const CheckDeviceCompatibility: React.FC = () => {
  return (
    <section>
      <GWTabletWrapper>
        <DesktopCheckoutDeviceCompatibility />
      </GWTabletWrapper>
      <GWOnlyMobileWrapper>
        <MobileCheckoutDeviceCompatibility />
      </GWOnlyMobileWrapper>
    </section>
  );
};

export default CheckDeviceCompatibility;
