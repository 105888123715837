import { Skeleton } from '@mui/material';
import Link from 'next/link';
import React from 'react';
import { useTheme } from 'styled-components';
import { useESim } from '../../../../../contexts/ESimContext';
import { useLocale } from '../../../../../contexts/LocalizationContext';
import RegionCard from '../../HomePageDesktop/components/RegionCard';
import { HomeMobileSection, RegionCardsWrapper } from '../HomePageMobileStyles';

const RegionalPlansSection: React.FC = () => {
  const theme = useTheme();
  const { loading, regionSkuList, countrySkuList } = useESim();
  const { messages } = useLocale();
  const { esim_home_explore_regional_plans_msg } = messages || {};

  return (
    <HomeMobileSection>
      <h2>{esim_home_explore_regional_plans_msg}</h2>
      <RegionCardsWrapper>
        {loading
          ? Array.from(Array(3).keys()).map((cur) => (
              <Skeleton
                sx={{
                  background: theme.palette.background.bg2,
                  width: '100%',
                  borderRadius: '16px',
                }}
                height="184px"
                key={cur}
                variant="rectangular"
              />
            ))
          : regionSkuList.slice(0, 4).map((cur) => (
              <Link
                passHref
                href={'/esim/' + cur.displaySlug}
                key={cur.countryName}
              >
                <a>
                  <RegionCard data={cur} />
                </a>
              </Link>
            ))}
      </RegionCardsWrapper>
    </HomeMobileSection>
  );
};

export default RegionalPlansSection;
