import styled from 'styled-components';

export const SearchContainer = styled.div`
  background: #fff;
  border-radius: 12px;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: text;
  height: 56px;
  position: relative;
`;

export const SearchIconContainer = styled.div`
  padding: 16px 16px 16px 16px;
  display: grid;
  place-content: center;
  min-width: 54.6px;
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  outline: none;
  color: #000;
  font-family: Onest-Medium;
  flex-grow: 1;
  border-radius: 0 12px 12px 0;
`;

export const SearchResultsContainer = styled.div`
  background: ${(props) => props.theme.palette.background.bg2};
  border-radius: 16px 0 0 16px;

  position: absolute;
  padding: 8px;
  top: 60px;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  padding-right: 0;
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 16px;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.background.bg2};
    border-radius: 16px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.background.bg3};
    border-radius: 16px;
  }
  &::-webkit-scrollbar-thumb:hover {
    border-radius: 16px;
  }
`;

export const SearchResultItem = styled.div`
  padding: 16px;
  display: flex;
  border-radius: 8px;
  align-items: center;
  gap: 12px;
  transition: 0.3s;
  cursor: pointer;
  margin-right: 8px;
  &:hover {
    background: ${(props) => props.theme.palette.background.bg3};
    gap: 16px;
  }
`;

export const FlagContainer = styled.div`
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  display: grid;
  place-content: center;
  background: ${(props) => props.theme.palette.background.bg1};
  border-radius: 50%;
  & > div {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    // background: #d80027;
  }
`;

export const NoResultsFound = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  font-size: 14px;
  & > p {
    font-family: Onest-Bold;
  }
`;
