import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import Button from '../../../components/NewButton/Button';
import { useLocale } from '../../../contexts/LocalizationContext';
import { saveEventV3 } from '../../../utils/eventTracking';
import { MobileContainer } from './CheckoutDeviceCompatibilityStyles';

const MobileCheckoutDeviceCompatibility: React.FC = () => {
  const router = useRouter();
  const { messages } = useLocale();
  const { esim_home_does_my_phone_support_esim_msg, esim_home_check_now_msg } =
    messages || {};

  return (
    <MobileContainer>
      <Image
        src="/images/esims/compatibility-illustration.webp"
        width="90px"
        height="128px"
      />
      <div>
        <h2>{esim_home_does_my_phone_support_esim_msg}</h2>
        <Link passHref href="/esim/compatibility">
          <a
            onClick={() =>
              saveEventV3({
                action: 'click',
                category: 'check_compatibility',
                label: '',
                value: [''],
                properties: 'mobile',
                from: router,
              })
            }
            style={{ width: 'fit-content', display: 'block' }}
          >
            <Button width="100%" buttonTheme="grey" height="40px">
              {esim_home_check_now_msg}
            </Button>
          </a>
        </Link>
      </div>
    </MobileContainer>
  );
};

export default MobileCheckoutDeviceCompatibility;
