import Image from 'next/image';
import React from 'react';
import { useTheme } from 'styled-components';
import { useLocale } from '../../../../../contexts/LocalizationContext';
import {
  HomeMobileSection,
  InfoDiv,
  WhyESimContainer,
  WhyESimSection,
} from '../HomePageMobileStyles';

const WhyESim: React.FC = () => {
  const theme = useTheme();
  const { messages } = useLocale();
  const {
    esim_lp_why_driffle_esim_msg,
    esim_home_digital_sim_card_msg,
    esim_home_destinations_msg,
    esim_home_instant_setup_msg,
  } = messages || {};

  return (
    <HomeMobileSection style={{ background: theme.palette.background.bg2 }}>
      <WhyESimSection>
        <h2>{esim_lp_why_driffle_esim_msg}</h2>
        <WhyESimContainer>
          <InfoDiv>
            <div>
              <Image
                src="/icons/esims/sim_logo.svg"
                width="32px"
                height="32px"
              />
            </div>
            <p>{esim_home_digital_sim_card_msg}</p>
          </InfoDiv>
          <InfoDiv>
            <div>
              <Image
                src="/icons/esims/location_on.svg"
                width="32px"
                height="32px"
              />
            </div>
            <p>{esim_home_destinations_msg}</p>
          </InfoDiv>
          <InfoDiv>
            <div>
              <Image src="/icons/esims/bolt.svg" width="32px" height="32px" />
            </div>
            <p>{esim_home_instant_setup_msg}</p>
          </InfoDiv>
          <InfoDiv>
            <div>
              <Image
                src="/icons/esims/support_agent.svg"
                width="32px"
                height="32px"
              />
            </div>
            <p>Dedicated Support</p>
          </InfoDiv>
        </WhyESimContainer>
      </WhyESimSection>
    </HomeMobileSection>
  );
};

export default WhyESim;
