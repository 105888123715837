import Image from 'next/image';
import React from 'react';
import { useLocale } from '../../../../../contexts/LocalizationContext';
import { RegionsMap } from '../../../../constants';
import { CountrySearchI } from '../../../../interfaces';
import { MapContainer, RegionCardContainer } from '../HomePageDesktopStyles';

interface RegionCardPropsI {
  data: CountrySearchI;
}

const RegionCard: React.FC<RegionCardPropsI> = ({ data }) => {
  const { messages } = useLocale();
  const { esim_home_number_countries_msg } = messages || {};

  return (
    <RegionCardContainer
      style={{
        background: RegionsMap[data.countryName.toLowerCase()]?.bg ?? '#5bb0ff',
      }}
    >
      <p>{data.countryName}</p>
      <p>
        {esim_home_number_countries_msg
          ? esim_home_number_countries_msg?.split('[--NUMBER--]')[0] +
            data.roamingCountriesName.split(',').length +
            esim_home_number_countries_msg?.split('[--NUMBER--]')[1]
          : ''}
      </p>
      <MapContainer>
        <div>
          {RegionsMap[data.countryName.toLowerCase()]?.map && (
            <Image
              src={RegionsMap[data.countryName.toLowerCase()]?.map}
              width="155px"
              height="95px"
              alt="map"
            />
          )}
        </div>
      </MapContainer>
    </RegionCardContainer>
  );
};

export default RegionCard;
