import styled from 'styled-components';

export const CatalogCardContainer = styled.div`
  width: auto;
  height: 94px;
  border-radius: 12px;
  padding: 16px;
  border: 1px solid ${(props) => props.theme.palette.border.b2};
  display: flex;
  align-items: center;
  cursor: pointer;
  background: ${(props) => props.theme.palette.background.bg2};
  transition: 0.3s;
  &:hover {
    background: ${(props) => props.theme.palette.background.bg3};
  }
`;

export const CountryInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  & p {
    font-size: 16px;
    font-family: Onest-Medium;
  }
`;

export const CountryPriceSubtitle = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => props.theme.palette.text.t2};
  & span {
    font-family: Onest-Bold;
    color: ${(props) => props.theme.palette.text.t1};
  }
`;

export const CountryImgContainer = styled.div`
  width: 32px;
  height: 32px;
  background: #000;
  border-radius: 50%;
  display: grid;
  place-content: center;
  & > div {
    width: 24px;
    height: 24px;
    background: #eaeaea;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
  }
`;
