import { Skeleton } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { useTheme } from 'styled-components';
import { useESim } from '../../../../../contexts/ESimContext';
import { useLocale } from '../../../../../contexts/LocalizationContext';
import { saveEventV3 } from '../../../../../utils/eventTracking';
import {
  RegionContainer,
  RegionsGrid,
  SectionContainer,
} from '../HomePageDesktopStyles';
import RegionCard from './RegionCard';

const RegionSection: React.FC = () => {
  const theme = useTheme();
  const router = useRouter();
  const { regionSkuList, loading } = useESim();
  const { messages } = useLocale();
  const {
    esim_home_traveling_to_multiple_countries_msg,
    esim_home_explore_regional_plans_msg,
  } = messages || {};

  return (
    <SectionContainer>
      <RegionContainer>
        <h2>{esim_home_traveling_to_multiple_countries_msg}</h2>
        <h2>{esim_home_explore_regional_plans_msg}</h2>
        <RegionsGrid>
          {loading
            ? Array.from(Array(9).keys()).map((cur) => (
                <Skeleton
                  key={cur}
                  variant="rectangular"
                  height="190px"
                  width={'100%'}
                  sx={{
                    background: theme.palette.background.bg3,
                    borderRadius: '12px',
                  }}
                />
              ))
            : regionSkuList.map((cur, i) => (
                <Link passHref key={i} href={'/esim/' + cur.displaySlug}>
                  <a
                    onClick={() => {
                      saveEventV3({
                        action: 'click',
                        category: 'explore_regions',
                        label: 'location_option',
                        value: [cur.displaySlug],
                        properties: '',
                        from: router,
                      });
                    }}
                  >
                    <RegionCard data={cur} />
                  </a>
                </Link>
              ))}
        </RegionsGrid>
      </RegionContainer>
    </SectionContainer>
  );
};

export default RegionSection;
