import { Skeleton } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { useTheme } from 'styled-components';
import { useESim } from '../../../../../contexts/ESimContext';
import { saveEventV3 } from '../../../../../utils/eventTracking';
import { CountrySearchI } from '../../../../interfaces';
import { CatalogContainer } from '../HomePageDesktopStyles';
import CatalogCard from './CatalogCard';

const CatalogList: React.FC<{
  selectedTab: number;
  list: CountrySearchI[];
}> = ({ selectedTab, list }) => {
  const router = useRouter();
  const { loading } = useESim();
  const theme = useTheme();

  return (
    <CatalogContainer>
      {loading
        ? Array.from(Array(16).keys()).map((cur) => (
            <Skeleton
              key={cur}
              variant="rectangular"
              height="94px"
              width={'100%'}
              sx={{
                background: theme.palette.background.bg2,
                borderRadius: '12px',
              }}
            />
          ))
        : list.map((cur, i) => (
            <Link
              passHref
              key={cur.countryCode + i}
              href={'/esim/' + cur.displaySlug}
            >
              <a
                onClick={() => {
                  saveEventV3({
                    action: 'click',
                    category:
                      selectedTab === 0
                        ? 'local_esims_tab'
                        : 'regional_esims_tab',
                    label: 'location_option',
                    value: [cur.displaySlug],
                    properties: '',
                    from: router,
                  });
                }}
              >
                <CatalogCard data={cur} />
              </a>
            </Link>
          ))}
    </CatalogContainer>
  );
};

export default CatalogList;
