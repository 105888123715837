import React from 'react';
import { CircleFlag } from 'react-circle-flags';
import { useTheme } from 'styled-components';
import { MaskIcon } from '../../../../../../components/Icons/Icons';
import { useLocale } from '../../../../../../contexts/LocalizationContext';
import { displayCurrencyValueESim } from '../../../../../../helper/helper';
import { useAppSelector } from '../../../../../../redux/hooks';
import { RegionsMap } from '../../../../../constants';
import { renderCountryName } from '../../../../../helper';
import { CountrySearchI } from '../../../../../interfaces';
import {
  CatalogCardContainer,
  CountryImgContainer,
  CountryInfo,
  CountryPriceSubtitle,
} from './CatalogCardStyles';

interface CatalogCardPropsI {
  data: CountrySearchI;
}

const CatalogCard: React.FC<CatalogCardPropsI> = ({ data }) => {
  const theme = useTheme();
  const { messages } = useLocale();
  const { esim_home_starting_from_price_msg } = messages || {};
  const global = useAppSelector((state) => state.global);

  return (
    <CatalogCardContainer>
      <div style={{ flexGrow: 1 }}>
        <CountryInfo>
          <CountryImgContainer>
            <div
              style={
                data.type === 'regional'
                  ? {
                      background:
                        RegionsMap[data.countryName.toLowerCase()]?.bg ?? '',
                    }
                  : {}
              }
            >
              {data.type === 'country' && (
                <CircleFlag countryCode={data.countryCode.toLowerCase()} />
              )}
            </div>
          </CountryImgContainer>
          <p>{renderCountryName(data.countryName)}</p>
        </CountryInfo>
        <CountryPriceSubtitle>
          {esim_home_starting_from_price_msg?.split('[--PRICE--]')[0]}
          <span>
            {displayCurrencyValueESim(
              data.lowestBundlePrice,
              global.countryArray?.filter((cur) => cur.country_code === 'US')[0]
                ?.currency_symbol,
              global.countryArray?.filter((cur) => cur.country_code === 'US')[0]
                ?.multiplier
            )}
          </span>
          {esim_home_starting_from_price_msg?.split('[--PRICE--]')[1]}
        </CountryPriceSubtitle>
      </div>
      <div>
        <MaskIcon
          url="/icons/arrow-down-chevron-24.svg"
          width="24px"
          height="24px"
          margin="0"
          selected
          style={{ transform: 'rotate(270deg)' }}
          color={theme.palette.text.t2}
        />
      </div>
    </CatalogCardContainer>
  );
};

export default CatalogCard;
