import Image from 'next/image';
import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useLocale } from '../../../../../contexts/LocalizationContext';
import {
  HeroBgImageContainer,
  HeroInfo,
  HeroInfoCard,
  HeroInfoCardBorder,
  HeroInfoCardContent,
  HeroInfoCardIconContainer,
  HeroMain,
  HeroSection,
  SearchContainer,
} from '../HomePageDesktopStyles';
import Search from './Search';

const HomeHero: React.FC = () => {
  const theme = useTheme();
  const { messages } = useLocale();
  const {
    esim_home_travel_anywhere_in_the_world_msg,
    esim_home_unlock_instant_connectivity_anytime_anywhere_msg,
    esim_home_digital_sim_card_msg,
    esim_home_instant_delivery_msg,
    esim_home_destinations_msg,
    esim_home_global_coverage_msg,
    esim_home_instant_setup_msg,
    esim_home_easy_to_use_msg,
  } = messages || {};

  const heroInfoData = useMemo(
    () => [
      {
        title: esim_home_digital_sim_card_msg,
        subtitle: esim_home_instant_delivery_msg,
        icon: '/icons/esims/sim_logo.svg',
      },
      {
        title: esim_home_destinations_msg,
        subtitle: esim_home_global_coverage_msg,
        icon: '/icons/esims/location_on.svg',
      },
      {
        title: esim_home_instant_setup_msg,
        subtitle: esim_home_easy_to_use_msg,
        icon: '/icons/esims/bolt.svg',
      },
    ],
    [
      esim_home_digital_sim_card_msg,
      esim_home_instant_delivery_msg,
      esim_home_destinations_msg,
      esim_home_global_coverage_msg,
      esim_home_instant_setup_msg,
      esim_home_easy_to_use_msg,
    ]
  );

  return (
    <HeroSection style={{ background: theme.palette.background.bg1 }}>
      <HeroBgImageContainer style={{ left: 0 }}>
        <Image
          layout="fill"
          src={'/images/esims/left-banner-esim-desktop.svg'}
          alt="esims bg"
        />
      </HeroBgImageContainer>
      <HeroMain>
        <h1>{esim_home_travel_anywhere_in_the_world_msg}</h1>
        <h3>{esim_home_unlock_instant_connectivity_anytime_anywhere_msg}</h3>
        <HeroInfo>
          {heroInfoData.map((cur, i) => (
            <>
              <HeroInfoCard key={i}>
                <HeroInfoCardIconContainer>
                  <div>
                    <Image src={cur.icon} width="25px" height="25px" />
                  </div>
                </HeroInfoCardIconContainer>
                <HeroInfoCardContent>
                  <p>{cur.title}</p>
                  <p>{cur.subtitle}</p>
                </HeroInfoCardContent>
              </HeroInfoCard>
              <HeroInfoCardBorder className="hero-info-border" />
            </>
          ))}
        </HeroInfo>
      </HeroMain>
      <HeroBgImageContainer
        style={{ right: '0px', width: '504px', height: '241px' }}
      >
        <Image
          layout="fill"
          src={'/images/esims/right-banner-esim-desktop.svg'}
          alt="esims bg"
        />
      </HeroBgImageContainer>
      <SearchContainer>
        <Search />
      </SearchContainer>
    </HeroSection>
  );
};

export default HomeHero;
