import Image from 'next/image';
import React from 'react';
import { HomeHeroSection } from '../HomePageMobileStyles';
import HomeSearch from './HomeSearch';

const HomeHero: React.FC = () => {
  return (
    <HomeHeroSection>
      <div
        style={{
          height: '259px',
          width: '100%',
          position: 'absolute',
          bottom: '0',
          zIndex: 0,
        }}
      >
        <Image
          layout="fill"
          src={'/images/esims/hero-home-mobile.svg'}
          alt="esims bg"
        />
      </div>
      <div style={{ position: 'relative', zIndex: 1 }}>
        <h2>Stay connected</h2>
        <h2>anytime, anywhere.</h2>
        <div style={{ margin: '0 16px', marginTop: '56px' }}>
          <HomeSearch />
        </div>
      </div>
    </HomeHeroSection>
  );
};

export default HomeHero;
