import React from 'react';
import {
  GWOnlyMobileWrapper,
  GWTabletWrapper,
} from '../../../styles/window.style';
import ESimHomePageDesktop from './HomePageDesktop';
import ESimHomePageMobile from './HomePageMobile';

const ESimHomePage: React.FC = () => {
  return (
    <>
      <GWTabletWrapper>
        <ESimHomePageDesktop />
      </GWTabletWrapper>
      <GWOnlyMobileWrapper>
        <ESimHomePageMobile />
      </GWOnlyMobileWrapper>
    </>
  );
};

export default ESimHomePage;
