import { Skeleton } from '@mui/material';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { CircleFlag } from 'react-circle-flags';
import { useTheme } from 'styled-components';
import { useESim } from '../../../../../contexts/ESimContext';
import { useLocale } from '../../../../../contexts/LocalizationContext';
import { saveEventV3 } from '../../../../../utils/eventTracking';
import { CountriesMap } from '../../../../constants';
import { renderCountryName } from '../../../../helper';
import {
  BigCountryCard,
  BigCountryCardHeader,
  CountryIllustrationContainer,
  HomeMobileSection,
  MobileSlider,
} from '../HomePageMobileStyles';

const PopularDestinationSection: React.FC = () => {
  const theme = useTheme();
  const router = useRouter();
  const { countrySkuList, loading } = useESim();
  const { messages } = useLocale();
  const { esim_home_explore_popular_destinations_msg } = messages || {};

  return (
    <HomeMobileSection>
      <h2>{esim_home_explore_popular_destinations_msg}</h2>
      <MobileSlider>
        {loading
          ? Array.from(Array(10).keys()).map((cur) => (
              <Skeleton
                key={cur}
                variant="rectangular"
                height="192px"
                width={'180px'}
                sx={{
                  background: theme.palette.background.bg2,
                  borderRadius: '12px',
                  minWidth: '180px',
                }}
              />
            ))
          : countrySkuList
              .filter((cur) =>
                ['CN', 'JP', 'US', 'IT', 'FR'].includes(cur.countryCode)
              )
              .map((cur) => (
                <Link passHref href={'/esim/' + cur.displaySlug}>
                  <a
                    onClick={() => {
                      saveEventV3({
                        action: 'click',
                        category: 'popular_destinations',
                        label: '',
                        value: [cur.displaySlug],
                        properties: '',
                        from: router,
                      });
                    }}
                  >
                    <BigCountryCard
                      style={{ background: CountriesMap[cur.countryCode].bg }}
                      key={cur.countryCode}
                    >
                      <BigCountryCardHeader>
                        <p>{renderCountryName(cur.countryName)}</p>
                        <div>
                          <CircleFlag
                            countryCode={cur.countryCode.toLowerCase()}
                          />
                        </div>
                      </BigCountryCardHeader>
                      <CountryIllustrationContainer>
                        <div>
                          <Image
                            src={CountriesMap[cur.countryCode].illustration}
                            layout="fill"
                          />
                        </div>
                      </CountryIllustrationContainer>
                    </BigCountryCard>
                  </a>
                </Link>
              ))}
      </MobileSlider>
    </HomeMobileSection>
  );
};

export default PopularDestinationSection;
