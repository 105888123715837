import React from 'react';
import CheckDeviceCompatibility from '../../../components/CheckDeviceCompatibility';
import FAQSection from '../../../components/FAQSection/FAQSection';
import HowItWorksSection from '../../../components/HowItWorksSection';
import ESimLayout from '../../../layouts/ESimLayout';
import { FAQSectionContainer } from '../../ESimPDP/ESimPDPDesktop/ESimPDPDesktopStyles';
import { MobileFooterText } from './HomePageMobileStyles';
import HomeHero from './components/HomeHero';
import PopularDestinationSection from './components/PopularDestinationSection';
import RegionalPlansSection from './components/RegionalPlansSection';
import RegionsSection from './components/RegionsSection';
import WhyESim from './components/WhyESim';

const ESimHomePageMobile: React.FC = () => {
  return (
    <ESimLayout>
      {/* <div style={{ padding: '16px 0' }}> */}
      <HomeHero />
      {/* </div> */}

      <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
        <PopularDestinationSection />
        {/* <IpDetectionBox /> */}
        {/* <ExistingPlan /> */}
        <RegionalPlansSection />
        <WhyESim />
        <HowItWorksSection />
        <CheckDeviceCompatibility />
        <RegionsSection />
        <FAQSectionContainer>
          <FAQSection />
        </FAQSectionContainer>
        <MobileFooterText>
          <span>Supercharge </span>
          your travel experience
        </MobileFooterText>
      </div>
    </ESimLayout>
  );
};

export default ESimHomePageMobile;
