import styled from 'styled-components';

export const CompatibilitySectionContainer = styled.div`
  display: flex;
  align-items: end;
  gap: 16px;
  padding: 88px 0;
  max-width: 1252px;
  margin: 0 auto;
`;

export const ContentContainer = styled.div`
  flex-grow: 1;
  & > div {
    max-width: 557px;

    & h2 {
      font-size: 42px;
      line-height: 64px;
      margin: 0;
    }
  }
`;

export const HeadingContainer = styled.div`
  margin-bottom: 32px;
  color: transparent;
  background-image: linear-gradient(180deg, #ffffff 0%, #494949 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const Subtitle = styled.p`
  font-family: Onest-Regular;
  font-size: 16px;
  line-height: 21.6px;
  text-align: left;
  color: ${(props) => props.theme.palette.text.t2};
  margin-bottom: 24px;
`;

export const ImageBackground = styled.div`
  width: 468px;
  min-width: 468px;
  height: 195px;
  min-height: 195px;
  border-radius: 40px;
  background: ${(props) => props.theme.palette.background.bg4};
  position: relative;
`;

export const ImageContainer = styled.div`
  width: 187px;
  min-width: 187px;
  height: 319px;
  min-height: 319px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export const MobileContainer = styled.div`
  display: grid;
  gap: 40px;
  grid-template-columns: 90px 1fr;
  place-items: center;
  padding: 24px;
  background: ${(props) => props.theme.palette.background.bg2};
  & h2 {
    font-size: 24px;
    line-height: 28px;
    margin-top: 0;
    font-family: Onest-Bold;
    color: ${(props) => props.theme.palette.text.t1};
    margin-bottom: 24px;
    color: transparent;
    background-image: linear-gradient(180deg, #ffffff 0%, #494949 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
