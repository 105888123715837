import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { useTheme } from 'styled-components';
import { MaskIcon } from '../../../../../components/Icons/Icons';
import { useLocale } from '../../../../../contexts/LocalizationContext';
import { MobileSearchInputContainer } from '../HomePageMobileStyles';

const HomeSearch: React.FC = () => {
  const theme = useTheme();
  const router = useRouter();
  const { messages } = useLocale();
  const { esim_home_where_are_you_visiting_msg } = messages || {};

  return (
    <Link passHref href="/esim/search">
      <a>
        <MobileSearchInputContainer>
          <div>
            <MaskIcon
              url="/icons/search-24.svg"
              width="24px"
              height="24px"
              color={theme.palette.text.t2}
              selected
              margin="0"
            />
          </div>
          <input
            type="text"
            placeholder={esim_home_where_are_you_visiting_msg}
          />
        </MobileSearchInputContainer>
      </a>
    </Link>
  );
};

export default HomeSearch;
