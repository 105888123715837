import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useESim } from '../../../../../contexts/ESimContext';
import { useLocale } from '../../../../../contexts/LocalizationContext';
import { getSearchResults } from '../../../../services/search';
import {
  CatalogSectionContainer,
  CatalogTab,
  CatalogTabsContainer,
  SectionContainer,
} from '../HomePageDesktopStyles';
import CatalogList from './CatalogList';

const CatalogSection: React.FC = () => {
  const theme = useTheme();
  const { messages } = useLocale();
  const { esim_home_local_esims_msg, esim_home_regional_esims_msg } =
    messages || {};
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { countrySkuList, loading, regionSkuList } = useESim();

  const handleGetCatalog = async () => {
    const data = await getSearchResults();
  };

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  useEffect(() => {
    handleGetCatalog();
  }, [handleGetCatalog]);

  return (
    <SectionContainer>
      <CatalogSectionContainer>
        <CatalogTabsContainer>
          <CatalogTab
            onClick={() => handleTabChange(0)}
            selected={selectedTab === 0}
          >
            {esim_home_local_esims_msg}
          </CatalogTab>
          <CatalogTab
            onClick={() => handleTabChange(1)}
            selected={selectedTab === 1}
          >
            {esim_home_regional_esims_msg}
          </CatalogTab>
        </CatalogTabsContainer>
        <CatalogList
          selectedTab={selectedTab}
          list={
            selectedTab === 0
              ? countrySkuList.slice(0, 16)
              : regionSkuList.slice(0, 16)
          }
        />
      </CatalogSectionContainer>
    </SectionContainer>
  );
};

export default CatalogSection;
