import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { saveEventV3 } from '../../utils/eventTracking';
import { CountrySearchI } from '../interfaces';
import { getSearchResults } from '../services/search';

const useSearch = () => {
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [results, setResults] = useState<CountrySearchI[]>([]);
  const [openResultsDropdown, setOpenResultsDropdown] =
    useState<boolean>(false);

  const handleGetSearchResults = async (searchQuery?: string) => {
    setLoading(true);
    try {
      saveEventV3({
        action: 'click',
        category: 'search',
        label: '',
        value: [searchQuery ?? ''],
        properties: '',
        from: router,
      });
      const data = await getSearchResults(searchQuery);
      setResults(data.countryList ?? []);
    } catch (e) {}
    setLoading(false);
  };

  useEffect(() => {
    if (!!searchQuery) {
      handleGetSearchResults(searchQuery);
    } else {
      setResults([]);
    }
  }, [searchQuery]);

  const handleSearchQuery = useCallback(
    debounce((value: string) => {
      setSearchQuery(value);
    }, 500),
    []
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    handleSearchQuery(value);
  };

  useEffect(() => {
    if (!loading && !!searchQuery) {
      setOpenResultsDropdown(true);
    } else {
      setOpenResultsDropdown(false);
    }
  }, [loading, searchQuery]);

  return {
    handleChange,
    results,
    loading,
    searchQuery,
    openResultsDropdown,
    setOpenResultsDropdown,
  };
};

export default useSearch;
